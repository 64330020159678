// Here you can add other styles
.test.alert.alert-success.fade.show {
    display: none;
}

canvas {
  height: 600px;
}

#reactgooglegraph-1 table {
    width: 100%;
}

#reactgooglegraph-1 td div {
    text-align: center;
    right: 0;
    margin: auto;
}

#reactgooglegraph-1 td:empty {
    display: none;
}

@media screen and (min-width: 1400px) {
  .container {
    width: 1370px;
  }
  
  #reactgooglegraph-1 td{
  	width:16.6667% !important

  }
}
@media screen and (min-width: 1600px) {
  .container {
    width: 1570px;
  }
  .card.text-white {
    font-size: 1.6rem;
  }
  .card.text-white .text-value-lg {
    font-size: 2rem;
  }
}
@media screen and (min-width: 1900px) {
  .container {
    width: 1870px;
  }
  canvas {
    height: 800px;
  }
}


@media screen and (max-width: 767px) {
  canvas {
      height: 300px;
  }
}

